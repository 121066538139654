import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Link, graphql } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Button from '../components/button'
import AnimatedArrow from '../components/animated-arrow'
import Slider, { NextSlide, CurrentSlide, MobileNav } from '../components/slider'
import useWindowSize from '../hooks/useWindowSize'

// Assets
import Pattern from '../images/title-pattern.inline.svg'

// Styles
import '../styles/pages/concept.scss'

const Tabs = ({ data, currentTabId, setCurrentTabId, mobileOnly, viewMode }) => {
  return (
    <ul
      className={`tabs-wrapper flex justify-between md:pl-0 md:justify-start md:space-x-8 md:text-lg uppercase px-4 mb-6 2xl:pl-9 ${
        mobileOnly ? 'xl:hidden' : 'hidden xl:flex'
      }`}
    >
      {data.map(info => (
        <li
          key={info.id}
          className={`${currentTabId === info.id ? 'text-tuya-iota' : 'text-tuya-rho'} ${
            info.active === false ? 'hidden' : ''
          }`}
        >
          <button className="focus:outline-none md:font-normal" onClick={() => setCurrentTabId(info.id)}>
            {viewMode === VIEW_MOBILE ? info.shortTitle : info.tabName}
          </button>
        </li>
      ))}
    </ul>
  )
}

const contentVariants = {
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
}

const InfoCards = ({ data, currentTabId, setCurrentTabId, currentTab, viewMode }) => {
  return (
    <div className="info-cards mb-14 md:mt-20 xl:mb-0">
      <Tabs
        data={data}
        currentTabId={currentTabId}
        setCurrentTabId={setCurrentTabId}
        mobileOnly={true}
        viewMode={viewMode}
      />
      <div className="card-wrapper xl:flex">
        <div className="bg-white flex flex-col justify-between h-full pl-8 pt-10 pr-7 pb-8 md:px-20 md:py-10 xl:px-10 xl:py-8 2xl:p-16 3xl:pl-32 3xl:pr-56 content-wrapper">
          <AnimatePresence exitBeforeEnter>
            <motion.h2
              variants={contentVariants}
              key={currentTabId}
              initial="hide"
              animate="show"
              exit="hide"
              className="text-tuya-iota font-normal xl:font-light xl:text-6xl xl:leading-[70px] xl:tracking-tightish"
              style={{ whiteSpace: 'break-spaces' }}
            >
              {currentTab.titleSplit.split(',').map(s => `${s}\n`)}
            </motion.h2>
          </AnimatePresence>
          <AnimatePresence exitBeforeEnter>
            <motion.p
              variants={contentVariants}
              key={currentTabId}
              initial="hide"
              animate="show"
              exit="hide"
              className="font-extralight md:font-light mt-8 xl:mt-auto 3xl:w-96"
            >
              {currentTab.description}
            </motion.p>
          </AnimatePresence>
          <Link className="text-tuya-iota mt-10 xl:mt-auto" to={currentTab.link}>
            {currentTab.linkLabel}
            <AnimatedArrow className="h-3 text-tuya-rho" />
          </Link>
        </div>
        <div className="fake-column"></div>
        <div className="hidden h-full items-center justify-center btn-wrapper md:flex md:w-16 xl:mx-8 3xl:mx-20">
          <a href="/reservation">
            <Button isDark isVertical>
              <Trans>reservation</Trans>
            </Button>
          </a>
        </div>
      </div>
    </div>
  )
}

const MainSection = ({ data, viewMode }) => {
  const [currentTabId, setCurrentTabId] = useState(data.infoCards[0].id)
  const currentTab = data.infoCards.find(x => x.id === currentTabId)
  const [showSlider, setShowSlider] = useState(true)

  const sliderMap = {
    food_slider: data.foodSlider,
    drinks_slider: data.drinksSlider,
    dining_slider: data.diningSlider,
  }

  const setTabId = id => {
    setShowSlider(false)
    setCurrentTabId(id)
    setTimeout(() => setShowSlider(true), 100)
  }

  return (
    <section className="main-section">
      <div className="wrapper grid">
        <div className="description md:pl-11 mb-8 md:mb-10 xl:pl-0 xl:pr-8 xl:border-b xl:h-full 2xl:pr-0 2xl:mb-0">
          <h3 className="text-3xl font-normal text-tuya-alpha md:text-6xl mb-5 md:mb-6 xl:text-5xl">
            {data.pageTitle}
          </h3>
          <div
            style={{ lineHeight: 1.75 }}
            className="font-light text-tuya-rho"
            dangerouslySetInnerHTML={{ __html: data.pageDescription }}
          />
          <Pattern className="hidden mt-10 md:block md:mt-6 xl:hidden" />
        </div>
        <Slider carousel={data.carousel} reversed>
          <NextSlide sliderId="first" />
          <CurrentSlide sliderId="first" />
          <MobileNav sliderId="first" mt={4} className="mb-16" />
        </Slider>

        <InfoCards
          data={data.infoCards}
          currentTabId={currentTabId}
          setCurrentTabId={setTabId}
          currentTab={currentTab}
          viewMode={viewMode}
        />
        {/* START grid row 2*/}
        <div className="hidden xl:block xl:row-start-2 xl:col-start-4 xl:mt-12 xl:ml-48 3xl:mx-auto">
          <Pattern className="hidden xl:block" />
        </div>
        {/* END - grid row 2 */}

        <Tabs data={data.infoCards} currentTabId={currentTabId} setCurrentTabId={setTabId} mobileOnly={false} />
        {showSlider &&
          (sliderMap[currentTab.sliderName].length > 1 ? (
            <Slider carousel={sliderMap[currentTab.sliderName]}>
              <NextSlide sliderId="second" />
              <CurrentSlide sliderId="second" />
              <MobileNav sliderId="second" mt={4} className="mb-16" />
            </Slider>
          ) : (
            <Slider carousel={sliderMap[currentTab.sliderName]}>
              <CurrentSlide sliderId="second" />
            </Slider>
          ))}
        <div className="reservation flex items-center justify-center md:hidden">
          <a href="/reservation">
            <Button isDark>
              <Trans>reservation</Trans>
            </Button>
          </a>
        </div>
      </div>
    </section>
  )
}

const VIEW_DESKTOP = 1
const VIEW_TABLET = 2
const VIEW_MOBILE = 3
const ConceptPage = ({ data: graphqlData, location }) => {
  const data = graphqlData.datoCmsConcept
  const windowSize = useWindowSize()
  const [viewMode, setViewMode] = useState(VIEW_DESKTOP)
  const { t } = useTranslation()

  useEffect(() => {
    if (windowSize.width >= 1024) {
      setViewMode(VIEW_DESKTOP)
    } else if (windowSize.width >= 640) {
      setViewMode(VIEW_TABLET)
    } else {
      setViewMode(VIEW_MOBILE)
    }
  }, [windowSize])
  return (
    <Layout className="concept-page" pathname={location.pathname} alwaysBlack>
      <SEO title={t('nav_concept')} />
      <MainSection data={data} viewMode={viewMode} />
    </Layout>
  )
}

export const query = graphql`
  query ConceptQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsConcept(locale: { eq: $language }) {
      pageTitle
      pageDescription
      carousel {
        id
        image {
          sizes {
            src
            srcSet
          }
        }
      }
      infoCards {
        description
        id
        tabName
        title
        shortTitle
        active
        link
        titleSplit
        linkLabel
        sliderName
      }
      foodSlider {
        id
        image {
          sizes {
            src
            srcSet
          }
        }
      }
      drinksSlider {
        id
        image {
          sizes {
            src
            srcSet
          }
        }
      }
      diningSlider {
        id
        image {
          sizes {
            src
            srcSet
          }
        }
      }
    }
  }
`

export default ConceptPage
